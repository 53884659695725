<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    clipped-right
    app
    color="white"
  >
    <v-img
      @click="$router.push({ name: 'Regions' })"
      @mouseover="changeCursor"
      @mouseout="resetCursor"
      contain
      :max-width="client == 'xylem' ? '160px' : '100px'"
      height="50px"
      :src="logo"
      alt="EOMAP Logo"
    ></v-img>
    <slot name="tools" v-if="region">
      <div id="eo-tool-btns">
        <header-buttons :region="region"></header-buttons>
      </div>
    </slot>
    <v-spacer />
    <div v-if="region">
      <home-btn :region="region" />
    </div>
    <v-spacer />
    <profile-menu v-if="loggedIn" />
    <v-btn v-else small color="primary" class="mr-5 ml-2" @click="login">
      Login
    </v-btn>
    <v-btn icon large class="mr-2" @click="openSupportCenter">
      <v-icon>mdi-help-circle-outline</v-icon>
    </v-btn>
    <support-center
      v-model="isSupportCenterOpen"
      v-if="$appConfig.keycloakClient !== 'coasts'"
      :userInfo="userInfo"
    ></support-center>
    <!--    <v-img
      class="hidden-sm-and-down"
      contain
      max-width="100px"
      height="50px"
      :src="logo"
      alt="EOMAP Logo"
    ></v-img>-->
  </v-app-bar>
</template>

<script>
import ProfileMenu from "@/core/components/bar/CoreBarProfile";
import SupportCenter from "@/core/components/bar/supportCenter/SupportCenter";
import { mapState } from "vuex";
import HeaderButtons from "@/core/components/HeaderButtons.vue";
import HomeBtn from "@/core/components/HomeBtn.vue";

export default {
  name: "CoreBar",
  components: {
    HomeBtn,
    HeaderButtons,
    ProfileMenu,
    SupportCenter
  },
  data: function() {
    return {
      portal_name: this.$appConfig.appName,
      logo: this.$appConfig.logo,
      region: null,
      isSupportCenterOpen: false
    };
  },
  computed: {
    ...mapState("app", ["showMenu"]),
    ...mapState("management", ["regions"]),
    loggedIn: function() {
      return this.$keycloak.authenticated;
    },
    client() {
      return this.$appConfig.keycloakClient;
    },
    userInfo() {
      const info = [this.$keycloak.userName, this.$keycloak.fullName];
      return info;
    }
  },
  methods: {
    login() {
      this.$keycloak.login();
    },
    changeCursor() {
      document.querySelector("div").style.cursor = "pointer";
    },
    resetCursor() {
      document.querySelector("div").style.cursor = "default";
    },
    defineRegion() {
      if (this.$route?.params?.regionId) {
        const region = this.regions.features.filter(
          feature => feature.id == this.$route.params.regionId
        );
        this.region = region[0];
      } else {
        this.region = null;
      }
    },
    openSupportCenter() {
      this.isSupportCenterOpen = true;
    }
  },
  watch: {
    "$route.params"() {
      this.defineRegion();
    },

    regions() {
      this.defineRegion();
    }
  },
  created() {
    this.defineRegion();
  }
};
</script>

<style scoped>
#eo-tool-btns {
  margin-left: 1rem;
}
</style>
