const AppConfig = {
  prod: {
    eoportal: {
      name: "eoportal",
      appName: "eoPortal",
      logo: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.svg"),
      logoPNG: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.png"),
      company: require("/src/core/assets/eoportal/byeomap_lightgrey.png"),
      language: "en",
      public: false,
      keycloakClient: "eoportal",
      manualUrl: "/docs/Userguide_eoappAQUA_20240419V1.pdf",
      manualUrlDe: "/docs/Userguide_eoappAQUA_20240419V1_de.pdf",
      trackingID: "7f5dac38-fb2a-47cd-b5ab-aaed37ae8f50",
      trackingDomains: "eoportal.de,www.eoportal.de"
    },
    eoapp: {
      name: "eoapp",
      appName: "eoapp AQUA",
      logo: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.svg"),
      logoPNG: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.png"),
      company: require("/src/core/assets/eoportal/byeomap_lightgrey.png"),
      language: "en",
      public: false,
      keycloakClient: "eoportal",
      manualUrl: "/docs/Userguide_eoappAQUA_20240419V1.pdf",
      manualUrlDe: "/docs/Userguide_eoappAQUA_20240419V1_de.pdf",
      trackingID: "7f5dac38-fb2a-47cd-b5ab-aaed37ae8f50",
      trackingDomains: "eoapp.de,www.eoapp.de"
    },
    aqua: {
      name: "aqua",
      appName: "eoapp AQUA",
      logo: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.svg"),
      logoPNG: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.png"),
      company: require("/src/core/assets/eoportal/byeomap_lightgrey.png"),
      language: "en",
      public: false,
      keycloakClient: "eoportal",
      manualUrl: "/docs/Userguide_eoappAQUA_20240419V1.pdf",
      manualUrlDe: "/docs/Userguide_eoappAQUA_20240419V1_de.pdf",
      trackingID: "7f5dac38-fb2a-47cd-b5ab-aaed37ae8f50",
      trackingDomains: "aqua.eoapp.de"
    },
    hypos: {
      name: "hypos",
      appName: "eoapp HYPOS",
      logo: require("/src/core/assets/hypos/hypos_logo.png"),
      company: require("/src/core/assets/eoportal/byeomap_lightgrey.png"),
      language: "en",
      public: false,
      keycloakClient: "hypos",
      manualUrl: "/docs/HYPOS_Portal_Manual_v04.pdf",
      manualUrlDe: "/docs/HYPOS_Portal_Manual_v04.pdf",
      trackingID: "08941757-85ea-4d69-ad56-103e7ca9af56",
      trackingDomains: "hypos.eoapp.de,www.hypos.eoapp.de"
    },
    xylem: {
      name: "xylem",
      appName: "eoapp Xylem",
      logo: require("/src/core/assets/xylem/logo.png"),
      company: require("/src/core/assets/eoportal/byeomap_lightgrey.png"),
      language: "en",
      public: false,
      keycloakClient: "xylem",
      manualUrl: "/docs/Userguide_eoappAQUA_20240419V1.pdf",
      manualUrlDe: "/docs/Userguide_eoappAQUA_20240419V1_de.pdf",
      trackingID: "aaaf45d4-f328-4611-ab31-b25efcdb61cf",
      trackingDomains: "xylem.eoapp.de,www.xylem.eoapp.de"
    },
    demo: {
      name: "eoapp",
      appName: "eoapp AQUA",
      logo: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.svg"),
      logoPNG: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.png"),
      company: require("/src/core/assets/eoportal/byeomap_lightgrey.png"),
      language: "en",
      public: false,
      keycloakClient: "eoportal",
      manualUrl: "/docs/Userguide_eoappAQUA_20240419V1.pdf",
      manualUrlDe: "/docs/Userguide_eoappAQUA_20240419V1_de.pdf"
    },
    coasts: {
      name: "coasts",
      appName: "COASTS",
      logo: require("/src/core/assets/coasts/logo.png"),
      language: "en",
      public: false,
      keycloakClient: "coasts",
      manualUrl: "/docs/Userguide_EOMAP_eoPortal_20210323V01.3.pdf",
      manualUrlDe: "/docs/Userguide_EOMAP_eoPortal_20210323V01.3.pdf",
      trackingID: "5a07d79c-9fb8-4109-b515-8c3baa300f09",
      trackingDomains: "coasts.eoapp.de,www.coasts.eoapp.de"
    }
  },
  dev: {
    eoportal: {
      name: "eoapp",
      appName: "eoapp AQUA",
      logo: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.svg"),
      logoPNG: require("/src/core/assets/eoportal/eoapp_AQUA_komplett.png"),
      company: require("/src/core/assets/eoportal/byeomap_lightgrey.png"),
      language: "de",
      public: false,
      keycloakClient: "eoportal",
      manualUrl: "/docs/Userguide_eoappAQUA_20240419V1.pdf",
      manualUrlDe: "/docs/Userguide_eoappAQUA_20240419V1_de.pdf",
      trackingID: "4f95eb72-9118-4a24-a571-32562d192699",
      trackingDomains: "localhost"
    },
    hypos: {
      name: "hypos",
      appName: "eoapp HYPOS",
      logo: require("/src/core/assets/hypos/hypos_logo.png"),
      company: require("/src/core/assets/eoportal/byeomap_lightgrey.png"),
      language: "en",
      public: false,
      keycloakClient: "hypos",
      manualUrl: "/docs/HYPOS_Portal_Manual_v04.pdf",
      manualUrlDe: "/docs/HYPOS_Portal_Manual_v04.pdf"
    },
    xylem: {
      name: "xylem",
      appName: "eoapp Xylem",
      logo: require("/src/core/assets/xylem/logo.png"),
      company: require("/src/core/assets/eoportal/byeomap_lightgrey.png"),
      language: "en",
      public: false,
      keycloakClient: "xylem",
      manualUrl: "/docs/Userguide_eoappAQUA_20240419V1.pdf",
      manualUrlDe: "/docs/Userguide_eoappAQUA_20240419V1_de.pdf"
    },
    coasts: {
      name: "coasts",
      appName: "COASTS",
      logo: require("/src/core/assets/coasts/logo.png"),
      language: "en",
      public: false,
      keycloakClient: "coasts",
      manualUrl: "/docs/Userguide_XYLEM_eoPortal_20210323V01.3.pdf",
      manualUrlDe: "/docs/Userguide_XYLEM_eoPortal_20210323V01.3.pdf"
    }
  }
};

export default AppConfig;
